import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Безконтактна автомийка PurityWave
			</title>
			<meta name={"description"} content={"Відкрийте для себе простоту та ефективність миття вашого автомобіля разом з нами, і ми зробимо кожне відвідування приємним та приємним."} />
			<meta property={"og:title"} content={"Безконтактна автомийка PurityWave"} />
			<meta property={"og:description"} content={"Відкрийте для себе простоту та ефективність миття вашого автомобіля разом з нами, і ми зробимо кожне відвідування приємним та приємним."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
		</Helmet>
		<Components.Contact />
		<Components.Nav />
		<Components.Hero>
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Наші послуги
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				Ознайомтесь із послугами безконтактної автомийки PurityWave
			</Override>
		</Components.Hero>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1559223694-98ed5e272fef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Догляд за автомобілем світового класу
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08:18:16.267Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Експрес-прання
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Швидке очищення: миттєво видаляє поверхневий бруд і пил, надаючи йому блиску.
Промивання ходової частини: очищає важкодоступні місця, запобігаючи іржі та корозії.
Wheel Blast: концентровані струмені очищають колеса та диски, видаляючи гальмівний пил та бруд.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08:18:16.306Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Делюкс-прання
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Покращене очищення: включає всі функції експрес-прання з підвищеною увагою до деталей. Потрійна пінка: додає шар захисту та блиску. Прозоре захисне покриття: захищає фарбу від забруднень довкілля.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08:18:16.257Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								ІДЕАЛЬНЕ ПРАННЯ
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Чищення з повним набором послуг: найбільш повний пакет, що поєднує всі функції Deluxe Wash з додатковими поліпшеннями.
Tire Shine: кондиціонує та покращує зовнішній вигляд шин, надаючи їм насичений глянсовий вигляд.
Захист від дощу: покращує видимість у негоду, відштовхуючи воду з лобового скла.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08:18:16.260Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-3.jpg?v=2024-05-07T08%3A18%3A16.260Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								ПАКЕТИ СЕЗОННОГО ДОГЛЯДУ
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Сезонні обробки: призначені для захисту вашого автомобіля від унікальних проблем кожного сезону. Зимовий щит: наносить захисне покриття для захисту від солі та дорожніх хімікатів.
Літнє сяйво: містить віск, що захищає від ультрафіолету та запобігає пошкодженню шкіри від сонця.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08:18:16.255Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-2.jpg?v=2024-05-07T08%3A18%3A16.255Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								ЕКО-ПАКЕТИ
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Екологічно чисті варіанти: створені для тих, хто надає пріоритет екологічному впливу, не жертвуючи при цьому якістю. Водозберігаюче прання: використовується мінімальна кількість води при максимальній ефективності очищення. Екологічна продукція: використовуються біорозкладні засоби для чищення, що не містять фосфатів.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="center"
					justify-self="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
						align-self="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" text-align="left" font="--base">
								Хочете дізнатися більше про те, як безконтактне миття PurityWave може змінити ваш досвід догляду за автомобілем? Завітайте до нас або зв'яжіться з нами, щоб отримати детальну інформацію про наші послуги і дізнатися, як ми можемо адаптувати наші пропозиції відповідно до ваших потреб.
							</Text>
						</Box>
					</Box>
					<Button
						margin="0px 0px 30px 0px"
						background="--color-darkL1"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Зв'язатися
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});